export const NOT_COMPAREABLE_PROPS = [
    'id',
    'description',
    'category',
    'currency',
    'searchQueries',
    'updated_at',
    '_updatedBy',
    '_createdBy',
    'search',
    'images'
]

export const PROPS_TRANSLATION = {
    title: 'Название',
    price: 'Цена',
    brand: 'Производитель',
    caliber: 'Калибр',
    used: 'Состояние',
    description: 'Описание',
    currency: 'Валюта',
    subCategory: 'Субкатегория',
    category: 'Категория',
    available: 'Доступность',
    barrelLength: 'Длинна ствола',
    magazineSize: 'Размер магазина',
    totalLength: 'Общая длинна',
    weight: 'Вес',
    origin: 'Страна производства'
}
