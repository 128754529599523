const localState = JSON.parse(localStorage.getItem('productLoadStorage'));

const initialState = {
};

const productsLoadReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_PRODUCTS":
            if (state[action.category]) {
                return {
                    ...state,
                    [action.category]: {products: [...state[action.category].products, ...action.products]}
                }
            } else {
                return {
                    ...state,
                    [action.category]: {products: [...action.products]}
                }
            }
            break
        default:
            return state;
    }
    
}
export default productsLoadReducer;