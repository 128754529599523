import React from "react";

const NotFound = () => {
  return (
    <div className="container">
      <h1 className="pageTitle">404</h1>
      <p>Такой страницы нет</p>
    </div>
  );
};

export default NotFound;
