export const CATEGORIES_TRANSLATION = {
    weapons: 'Оружие',
    protection: 'Средства защиты',
    knifes: 'Ножи',
    ammunition: 'Патроны',
    clothes: 'Одежда и обувь',
    accessories: 'Аксессуары',
    sights: 'Прицелы',
    knives: 'Ножи',
}

export const SUB_CATEGORIES_TRANSLATION = {
    // Weapons
    pistols: 'Пистолеты',
    rifles: 'Нарезное оружие',
    shotguns: 'Гладкоствольное оружие',
    traumatic: 'Травматическое оружие',
    pneumatic: 'Пневматическое оружие',
    
    // Ammunition
    for_shotguns: 'Патроны Для Гладкоствольного оружия',
    for_rifles: 'Патроны Для Нарезного оружия',
    for_pneumatic: 'Патроны Для Пневматического оружия',
    for_traumatic: 'Патроны Для Травматичесеого оружия',

    // Accessories
    gun_care: 'Уход за оружием',
    belts: 'Ремни',
    cases: 'Чехлы',
    bipods: 'Сошки',
    special_tools: 'Инструменты',
    traps: 'Капканы',
    stuffed_animals: 'Чучела',
    sights: 'Оптика',
    headphones_glasses: 'Наушники и очки',
    rest_accessories: 'Остальные аксессуары',

    // Protection
    pepper_sprays: 'Перцовые балончики',
    stun_guns: 'Электрошокеры',
    rest_protection: 'Остальные средства защиты',
}
